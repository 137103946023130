import { LanguageType } from './types';

export const extensionMap: { [key: string]: LanguageType } = {
  '(.*)\\.png': LanguageType.Image,
  '(.*)\\.jpg': LanguageType.Image,
  '(.*)\\.jpeg': LanguageType.Image,
  '(.*)\\.svg': LanguageType.Image,
  '(.*)\\.css': LanguageType.CSS,
  '(.*)Dockerfile': LanguageType.Dockerfile,
  '(.*)\\.html': LanguageType.HTML,
  '(.*)\\.jade': LanguageType.Jade,
  '(.*)\\.java': LanguageType.Java,
  '(.*)\\.js': LanguageType.Javascript,
  '(.*)\\.jsx': LanguageType.Javascript,
  '(.*)\\.json': LanguageType.JSON,
  '(.*)\\.md': LanguageType.Markdown,
  '(.*)pom\\.xml': LanguageType.Maven,
  '(.*)\\.patch': LanguageType.Patch,
  '(.*)\\.py': LanguageType.Python,
  '(.*)\\.rb': LanguageType.Ruby,
  '(.*)\\.sass': LanguageType.Sass,
  '(.*)\\.sh': LanguageType.Shell,
  '(.*)\\.ts': LanguageType.Typescript,
  '(.*)\\.tsx': LanguageType.Typescript,
  '(.*)\\.xml': LanguageType.XML,
  '(.*)\\.yml': LanguageType.Yaml,
  '(.*)\\.yaml': LanguageType.Yaml,
  '(.*)yarn\\.lock': LanguageType.Yarn,
};
